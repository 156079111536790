// Color system

// scss-docs-start gray-color-variables
$white: #fff !default;
$gray-100: #eef1f3 !default;
// $gray-100: #f8f9fa !default;
// $gray-100: #efefef !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
// $gray-700: #495057 !default;
$gray-700: #3a3a3a !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;
// scss-docs-end gray-color-variables

// fusv-disable
// scss-docs-start gray-colors-map
$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900,
) !default;
// scss-docs-end gray-colors-map
// fusv-enable

// scss-docs-start color-variables
$blue: #0d6efd !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #198754 !default;
$teal: #00a9a6 !default;
$cyan: #0dcaf0 !default;
// scss-docs-end color-variables

// scss-docs-start colors-map
$colors: (
  "blue": $blue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "white": $white,
  "gray": $gray-600,
  "gray-dark": $gray-800,
) !default;
// scss-docs-end colors-map

// scss-docs-start theme-color-variables
$primary: $teal !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-700 !default;
// scss-docs-end theme-color-variables

// scss-docs-start theme-colors-map
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
) !default;
// scss-docs-end theme-colors-map

// scss-docs-start font-variables
$font-family-sans-serif: "Roboto", sans-serif;
$font-size-base: 1rem;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            700 !default;
$h1-font-size: ($font-size-base * 4)-0.1 !default;
$h2-font-size: ($font-size-base * 2.75)-0.1 !default;
$h3-font-size: ($font-size-base * 1.75)-0.1 !default;
$h4-font-size: ($font-size-base * 1.5)-0.1 !default;
$h5-font-size: ($font-size-base * 1.375)-0.1 !default;
$h6-font-size: ($font-size-base * 1.25)-0.1 !default;
$h7-font-size: ($font-size-base * 1.125)-0.1 !default;
// $h1-font-size: ($font-size-base * 4.125)-0.1 !default;
// $h2-font-size: ($font-size-base * 2.875)-0.1 !default;
// $h3-font-size: ($font-size-base * 1.875)-0.1 !default;
// $h4-font-size: ($font-size-base * 1.625)-0.1 !default;
// $h5-font-size: ($font-size-base * 1.5)-0.1 !default;
// $h6-font-size: ($font-size-base * 1.375)-0.1 !default;
// $h7-font-size: ($font-size-base * 1.25)-0.1 !default;
$h8-font-size: $font-size-base !default;
// scss-docs-end font-variables

// scss-docs-start font-sizes
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  7: $h7-font-size,
  8: $h8-font-size,
) !default;
// scss-docs-end font-sizes

// scss-docs-start display-headings
$display-font-sizes: (
  1: 4.375rem,
  2: 3.125rem,
  3: 2.125rem,
  4: 1.875rem,
  5: 1.75rem,
  6: 1.625rem,
  7: 1.5rem,
  8: 1.25rem,
) !default;
// scss-docs-end display-headings

// scss-docs-start grid-breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px,
) !default;
// scss-docs-end grid-breakpoints

// scss-docs-start container-max-widths
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
  xxxl: 1560px,
) !default;
// scss-docs-end container-max-widths

// scss-docs-start carousel-variables
$carousel-caption-width: 80% !default;
// scss-docs-end carousel-variables

// scss-docs-start rfs
$rfs-base-value: 1.25rem !default;
$rfs-breakpoint: 1500px !default;
// scss-docs-end rfs

// scss-docs-start grid
$grid-columns: 16 !default;
$grid-gutter-width: 1.5rem !default;
$grid-row-columns: 8 !default;
// scss-docs-end grid

// Option
// $enable-rounded: false !default;
$border-radius: 0.156rem !default;

// Card
$card-cap-bg: none !default;

// Settings for the `<body>` element.
$body-bg: $white !default;
$body-color: $gray-700 !default;

// Links
$link-decoration: none !default;

// scss-docs-start btn-variables
$btn-padding-y: 0.4rem !default;
$btn-padding-x: 1.5rem !default;
$btn-font-weight:  $font-weight-bold;
// scss-docs-end btn-variables

// scss-docs-start accordion-variables
$accordion-padding-x: 0rem !default;
$accordion-body-padding-x: $accordion-padding-x !default;
$accordion-button-padding-x: $accordion-padding-x !default;
$accordion-button-active-color: shade-color($primary, 10%) !default;
$accordion-button-active-bg: none !default;
$accordion-button-focus-border-color: none !default;
$accordion-button-focus-box-shadow: none !default;
$accordion-icon-active-color: none !default;
// scss-docs-start accordion-variables

// scss-docs-start alert-variables
$alert-bg-scale: -95% !default;
$alert-border-scale: -95% !default;
// scss-docs-end alert-variables

// scss-docs-start form-input-variables
$input-bg: $gray-100 !default;
$input-color: $body-color !default;
$input-border-color: $gray-100 !default;
$input-placeholder-color: $gray-500 !default;
$form-file-button-color:none !default;
$form-file-button-bg:  none !default;
$form-file-button-hover-bg: none !default;
// scss-docs-end form-input-variables

// scss-docs-start z-index-variables
$zindex-sticky: 1000;
$zindex-dropdown: 1020;
// scss-docs-end z-index-variables

$box-shadow: 0 0.5rem 1rem rgba($black, 0.15) !default;
$breadcrumb-divider-color: $gray-700;