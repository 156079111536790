// General
html {
  scroll-behavior: smooth;
}

* {
  transition: all 0.1s;
  -webkit-transition: all 0.1s;
  -moz-transition: all 0.1s;
  -ms-transition: all 0.1s;
  -o-transition: all 0.1s;
  outline: none !important;
}

// Navbar
.nav-speacial {
    position: relative;
    display: inline-block;
    &:after {
      content: "";
      background: $primary;
      height: 3px;
      position: absolute;
      bottom: 0;
      transition: .16s all 0.025s;
    }
    &:after {
      left: 100%;
      right: 0;
    }
    &:hover ~ &:after{
      left: 0;
      right: 100%;
    }
    &:hover::after{
      left: 0;
      right: 0;
    } 
}



.navbar-brand img {
  width: 200px;
  @media screen and (max-width: 1024px) {
    width: 140px;
  }
}
.navbar-light .navbar-toggler {
  color: rgba($primary, 1) !important;
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 169, 166, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}
// Search
.search-container {
  &#wrap {
    display: inline-block;
    position: relative;
    padding: 0;
    position: relative;
  }

  input[type="text"] {
    height: 40px;
    font-size: $h5-font-size;
    display: inline-block;
    border: none;
    outline: none;
    color: $dark;
    padding: 3px;
    padding-right: 60px;
    width: 0px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
    transition: width 0.4s cubic-bezier(0, 0.795, 0, 1);
    cursor: pointer;
    background: none;
    &:focus:hover {
      border-bottom: 1px solid $dark;
      background: white !important;
    }
    &:focus {
      width: 50vw;
      @media screen and (min-width: 1024px) {
        width: 64vw;
      }
      @media screen and (min-width: 1140px) {
        width: 54vw;
      }
      @media screen and (min-width: 1200px) {
        width: 60vw;
      }
      @media screen and (min-width: 1400px) {
        width: 22vw;
      }
      z-index: 1;
      background: white !important;
      border-bottom: 1px solid $primary;
      cursor: text;
    }
  }

  button[type="submit"] {
    border: none;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    cursor: pointer;
    opacity: 0.4;
    cursor: pointer;
    transition: opacity 0.4s ease;
    background-color: white;
    &:hover {
      opacity: 0.8;
    }
  }
}

// All Extend
%transition {
  transition: all 0.6s;
}

%card{
  @extend %transition;
  &:hover {
    .btn-outline-dark {
      background-color: $white;
      border-color: $primary;
      color: $primary;
    }
    .btn-dark{
      background-color: $primary;
      border-color: $primary;
      color: $dark;
    }
  }
  .card-title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 2.5em;
  }
}

// Donation
.donation {
  .card {
    @extend %card;
  }
  .owl-item{
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: auto !important;
    .card {
     @extend %card;
     }
  }
  .owl-prev, .owl-next{
    background-color: transparent !important;
  }
  .owl-stage {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
  .carousel-control-next-icon{
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%233a3a3a'%3E%3Cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E") !important;
    right: -30px;
    @media screen and (max-width:576px) {
      right: 0px;
    }
    top: 40%;
    position: absolute;
  }
  .carousel-control-prev-icon{
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%233a3a3a'%3E%3Cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3E%3C/svg%3E") !important;
    left: -30px;
    @media screen and (max-width:576px) {
      left: 0px;
    }
    top: 40%;
    position: absolute;
  }
  
  .carousel {
    .carousel-item {
      .card {
        @extend %transition;
        &:hover {
          .btn-outline-dark {
            background-color: $white;
            border-color: $primary;
            color: $primary;
          }
          .btn-dark{
            background-color: $primary;
            border-color: $primary;
            color: $dark;
          }
        }
        .card-title {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          height: 2.5em;
        }
      }
      --bs-gutter-x: 1rem;
      --bs-gutter-y: 0;
      padding-top: calc(var(--bs-gutter-y) * -1);
      padding-right: calc(var(--bs-gutter-x) / -2);
      padding-left: calc(var(--bs-gutter-x) / -2);

      .col-md-8,
      .col-lg-4,
      .col-16 {
        padding: 0.5rem;
      }
    }
    .carousel-control-next {
      right: -3rem;
      width: 5%;
    }
    .carousel-control-prev {
      left: -3rem;
      width: 5%;
    }
    @media (max-width: 768px) {
      .carousel-control-next {
        right: 0rem;
      }
      .carousel-control-prev {
        left: 0rem;
      }
    }
    @media (min-width: 768px) {
      .carousel-control-next {
        right: -2rem;
      }
      .carousel-control-prev {
        left: -2rem;
      }
    }
    @media (max-width: 768px) {
      .carousel-inner .carousel-item > .card {
        display: none;
      }
      .carousel-inner .carousel-item > .card:first-child {
        display: block;
      }
    }

    .carousel-inner .carousel-item.active,
    .carousel-inner .carousel-item-start,
    .carousel-inner .carousel-item-next,
    .carousel-inner .carousel-item-prev {
      display: flex;
      // transition-duration: 10s;
    }

    /* display 4 */
    @media (min-width: 576px) {
      .carousel-inner .carousel-item-right.active,
      .carousel-inner .carousel-item-next,
      .carousel-item-next:not(.carousel-item-start) {
        transform: translateX(25%) !important;
      }

      .carousel-inner .carousel-item-left.active,
      .carousel-item-prev:not(.carousel-item-end),
      .active.carousel-item-start,
      .carousel-item-prev:not(.carousel-item-end) {
        transform: translateX(-25%) !important;
      }

      .carousel-item-next.carousel-item-start,
      .active.carousel-item-end {
        transform: translateX(0) !important;
      }

      .carousel-inner .carousel-item-prev,
      .carousel-item-prev:not(.carousel-item-end) {
        transform: translateX(-25%) !important;
      }
    }
  }
}

// Donation counter
.donation-counter {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    // background: rgba(0, 0, 0, 0.5);
    background: rgb(0, 169, 166);
    background: linear-gradient(
      90deg,
      rgba(0, 169, 166, 1) 30%,
      rgba(0, 169, 166, 0.8) 50%,
      transparent 100%
    );
  }
}
// Buttons
.btn-group {
  @media screen and(max-width:576px) {
    width: 100%;
  }
}
.btn {
  text-transform: uppercase;
  @media screen and (max-width: 320px) {
    padding: 0.4rem 1rem;
  }
}

// News
.news, .projects, .campaigns {
  .card {
    &:hover {
      .btn-outline-dark {
        background-color: $dark;
        border-color: $dark;
        color: white !important;
      }
    }
  }
}

// Accordion style
.accordion-button {
  &::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2300a9a6'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
    background-color: $light;
    padding: 18px;
    border-radius: 50rem;
    background-position: center;
  }
  &:not(.collapsed){
    box-shadow: none !important;
  }
}

// Footer
footer {
  color: black;
  a {
    color: black;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
      color: black;
    }
  }
  .bg-white a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

// Cookie Bar
.cookiealert {
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 24px !important;
  z-index: 1030;
  //opacity: 0;
  border-radius: 0;
  //transform: translateY(100%);
  transition: all 500ms ease-out;
  background: $white;
  border: 1px solid #eeeeee;

  &.show {
    opacity: 1 !important;
    transform: translateY(0%);
    transition-delay: 1000ms;
  }

  @media (min-width: 0px) and (max-width: 768px) {
    width: 66%;
  }

  @media (min-width: 768px) {
    width: 50%;
  }

  @media (min-width: 1200px) {
    width: 30%;
  }
}

// Back to top
.back-top {
  position: fixed;
  bottom: 1%;
  right: 1%;
  z-index: 9;
  display: none;

  a {
    width: 60px;
    line-height: 40px;
    color: #fff;
    display: inline-block;
    border-radius: 5px;
    text-align: center;
    font-size: 24px;
    padding: 0px;

    &:hover {
      -webkit-transform: translateY(-1%);
      -ms-transform: translateY(-1%);
      -moz-transform: translateY(-1%);
      transform: translateY(-1%);
    }
  }
}

// Form control
.form-control {
  background-clip: border-box;
  &::file-selector-button, &::-webkit-file-upload-button {
    width: 30px;
  }
  &#formFile::before {
    content: url('data:image/svg+xml; utf8,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-file-earmark-pdf" viewBox="0 0 16 16"><path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/><path d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z"/></svg>');
    padding:3px;
    position: absolute;
    z-index: 2;
    display: block;
    background-color: $light;
    width: 30px;
  }
  
}

// Video
.video-bg {
  --fullGrid: 1 / 1 / -1 / -1;
  /* Span the full grid */
  grid-area: var(--fullGrid);

  /* Re-size video to cover full screen while maintaining aspect ratio */
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;

  /* Display video below overlay */
  z-index: -1;
}

.video-bg::-webkit-media-controls {
  display: none !important;
}

.video-overlay {
  /* Span the full grid */
  grid-area: var(--fullGrid);

  /* Center Content */
  display: grid;
  justify-content: center;
  align-content: center;
  text-align: center;

  /* Semi-transparent background */
  background-color: rgba(0, 0, 0, 0.55);
}

// Tel input
.iti {
  width: 100% !important;
}
.iti__flag {
  background-image: url("/themes/hasene/dist/img/flags.png");
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-image: url("/themes/hasene/dist/img/flags@2x.png");
  }
}
.iti__flag-container { 
  height: 38px!important; 
}

// Search Suggestion
.ui-widget.ui-widget-content {
  border: none !important;
  border-radius: $border-radius !important;
  box-shadow: $box-shadow !important;
  z-index: 1030;
}
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-button.ui-state-active:hover {
  border: none !important;
  background: $primary !important;
  font-weight: normal;
  color: #ffffff;
  border-radius: $border-radius !important;
}

// Maps
.maps{
  iframe{
    width: 100%;
    height: 300px;
  }
}

.breadcrumb{
  a{
    color: $dark !important;
  }
  .active{
    color: rgba($dark, .6) !important;
  }
}