// Selection
@mixin selection {
  ::-moz-selection {
    @content;
  }
  ::selection {
    @content;
  }
}
@include selection {
  color: white;
  background: rgba($primary, 0.6);
}
